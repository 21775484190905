import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    seancesLoaded: { type: Boolean, default: false },
    archiveLoaded: { type: Boolean, default: false },
    equipmentLoaded: { type: Boolean, default: false },
    reportsLoaded: { type: Boolean, default: false },
    stationId: Number,
    equipmentId: Number
  };

  static targets = ['archiveTypeTab', 'seancesResultContainer', 'equipmentTabContainer', 'reportsResultContainer'];

  loadSeances() {
    if(this.stationIdValue && !this.seancesLoadedValue){
      const url = `/stations/${this.stationIdValue}/seances`;
      fetch(url)
        .then(response => response.text())
        .then(html => {
          this.seancesResultContainerTarget.innerHTML = html;
          this.seancesLoadedValue = true;
        });
    }
  }

  loadEquipment() {
    if (this.equipmentLoadedValue) return;

    const url = `/stations/${this.stationIdValue}/equipment/${this.equipmentIdValue}`
    fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/javascript' } })
      .then(response => response.text())
      .then(html => {
        this.equipmentTabContainerTarget.innerHTML = html;
        if (window.feather) window.feather.replace({ width: 14, height: 14 });
        this.equipmentLoadedValue = true;
      });
  }

  selectFirstArchiveTab() {
    if (this.hasArchiveTypeTabTarget && !this.archiveLoadedValue){
      this.archiveTypeTabTarget.click();
      this.archiveLoadedValue = true;
    }
  }

  loadReports() {
    if(this.stationIdValue && !this.reportsLoadedValue){
      const url = `/stations/${this.stationIdValue}/user_reports`;
      fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/javascript' } })
        .then(response => response.text())
        .then(html => {
          this.reportsResultContainerTarget.innerHTML = html;
          this.reportsLoadedValue = true;
          flatpickr('.flatpickr-report', {
            enableTime: true,
            dateFormat: "d.m.Y H:i",
            defaultHour: '10'
          });
        });
    }
  }
}
