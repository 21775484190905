// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../stylesheets/application.css';
import Rails from '@rails/ujs';
import "../controllers";
global.Rails = Rails;
import ApexCharts from 'apexcharts';
global.ApexCharts = ApexCharts;
require("@rails/ujs").start();
//require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("@coreui/coreui");
window.$ = window.jQuery = require('jquery');
require("@popperjs/core");
require("bootstrap");
require("bootstrap-material-design");
require("ion-rangeslider");
//require("mdbootstrap/js/mdb.min");
require("flatpickr");
const Russian = require("flatpickr/dist/l10n/ru.js").default.ru;
flatpickr.localize(Russian); // default locale is now Russian
flatpickr('.flatpickr-basic');
import  monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import  'flatpickr/dist/plugins/monthSelect/style.css';
flatpickr(".flatpickr-month", {
  static: true,
  altInput: true,
  plugins: [new monthSelectPlugin({shorthand: false, dateFormat: "m.Y", altFormat: "F Y"})]
});

require('select2/dist/js/select2.full');
// require('select2/dist/css/select2.min.css');
require('select2/dist/js/i18n/ru.js');
require("../gfonts/gfonts");

require('leaflet');
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// require('../vendor/maps/leaflet');
require('../vendor/maps/map-leaflet');

require('../vendor/charts/chart-apex');

//import "controllers"

require("pace");
//require("perfect-scrollbar");
//import '../helpers/nested-forms/addFields';
//import '../helpers/nested-forms/removeFields';

window.feather = require('feather-icons');
if (window.feather) window.feather.replace({ width: 14, height: 14 });

/*document.addEventListener("turbolinks:load", () => {*/
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('body').bootstrapMaterialDesign();
  var Inputmask = require('inputmask')

  $('.filter_datatable').keydown(function(e) {
		if (e.which == 13) {
			$(this).submit();
		}
	});
/*});*/


  $('.filter_datatable').keydown(function(e) {
    if (e.which == 13) {
        $(this).submit();
    }
});

require ("../vendor/custom");
//require ("../vendor/feather");

//#TODO не работает в определенных случай (при редактировании не всегда подтягивает js-код)
//import '../companies/search';
