import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    reportLoaded: { type: Boolean, default: false },
    reportOpened: { type: Boolean, default: false },
    layerId: Number
  };

  static targets = ['bodyContainer'];

  loadReport(event) {
    let eventTarget = event.target;
    if(!this.reportLoadedValue){
      const url = `/reports/explorer_company?layer_id=${this.layerIdValue}`
      fetch(url, { method: 'POST', headers: { 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/javascript' } })
        .then(response => response.text())
        .then(html => {
          this.reportLoadedValue = true;
          this.appendNewContent(html.trim(), eventTarget);
          this.switchReportView(eventTarget);
        });
    }
    else{
      this.switchReportView(eventTarget);
    }
  }

  switchReportView(eventTarget){
    let classList = eventTarget.classList;
    if(!this.reportOpenedValue){
      this.reportOpenedValue = true;
      classList.remove('plus-icon');
      classList.add('minus-icon');
      eventTarget.parentNode.parentNode.nextElementSibling.classList.remove('d-none');
    }
    else{
      this.reportOpenedValue = false;
      classList.remove('minus-icon');
      classList.add('plus-icon');
      eventTarget.parentNode.parentNode.nextElementSibling.classList.add('d-none');
    }
  }

  appendNewContent(html_string, eventTarget) {
    let currentTr = eventTarget.parentNode.parentNode;
    console.log(currentTr.cells.length)
    let lengthTd =  currentTr.cells.length
    let currentTBody = currentTr.parentNode;
    let nextSibling = currentTr.nextSibling;
    let new_content = document.createElement('tr');
    new_content.innerHTML = '<td colspan="'.concat(lengthTd,'">', html_string, '</td>');
    while (new_content.firstChild) {
      currentTBody.insertBefore(new_content.firstChild, nextSibling);
    }
  }
}
