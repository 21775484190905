import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    unicloudUpdateApp: Number,
    unicloudSettings: Number,
    unicloudSendSms: String,
    sessionConfigureNotEditable: Boolean,
    unicloudSendJson: String,
    stationId: Number,
    equipmentId: Number,
    configurationLoaded: { type: Boolean, default: false }
  };

  static targets = ['updateApp', 'updateAppCancel', 'sendUpdateAppMessage', 'sendSettings', 'sendSettingsMessage', 'sendSms', 'sendSmsMessage', 'sendJson', 'sendJsonMessage', 'sendJsonDeleteButton', 'parameters', 'configurationLogsResultContainer'];

  initialize(){
    this.initUpdateApp();
    this.initUpdateAppCancel();
    this.initSendUpdateAppMessage();
    this.initSendSettings();
    this.initSendSettingsMessage();
    this.initSendSms();
    this.initSendSmsMessage();
    this.initSendJson();
    this.initSendJsonMessage();
    this.initSendJsonDeleteButton();
    this.initParameters();
  }

  initUpdateApp(){
    if (!this.hasUpdateAppTarget) return;

    this.unicloudUpdateAppValue === 1 || this.sessionConfigureNotEditableValue ? this.updateAppTarget.classList.add("disabled-block") : this.updateAppTarget.classList.remove("disabled-block");
  }

  initUpdateAppCancel(){
    if (!this.hasUpdateAppCancelTarget) return;

    this.unicloudUpdateAppValue === 0 || this.sessionConfigureNotEditableValue ? this.updateAppCancelTarget.classList.add("d-none") : this.updateAppCancelTarget.classList.remove("d-none");
  }

  initSendUpdateAppMessage() {
    if (!this.hasSendUpdateAppMessageTarget) return;

    this.unicloudUpdateAppValue === 1 ? this.sendUpdateAppMessageTarget.classList.remove("d-none") : this.sendUpdateAppMessageTarget.classList.add("d-none");
  }

  initSendSettings() {
    if (!this.hasSendSettingsTarget) return;

    this.unicloudSettingsValue === 1 || this.sessionConfigureNotEditableValue ? this.sendSettingsTarget.classList.add("disabled-block") : this.sendSettingsTarget.classList.remove("disabled-block");
  }

  initSendSettingsMessage() {
    if (!this.hasSendSettingsMessageTarget) return;

    this.unicloudSettingsValue === 1 ? this.sendSettingsMessageTarget.classList.remove("d-none") : this.sendSettingsMessageTarget.classList.add("d-none");
  }

  initSendSms(){
    if (!this.hasSendSmsTarget) return;

    this.unicloudSendSmsValue || this.sessionConfigureNotEditableValue ? this.sendSmsTarget.classList.add("disabled-block") : this.sendSmsTarget.classList.remove("disabled-block");
  }

  initSendSmsMessage() {
    if (!this.hasSendSmsMessageTarget) return;

    this.unicloudSendSmsValue ? this.sendSmsMessageTarget.classList.remove("d-none") : this.sendSmsMessageTarget.classList.add("d-none");
  }

  initSendJson() {
    if (!this.hasSendJsonTarget) return;

    this.unicloudSendJsonValue || this.sessionConfigureNotEditableValue ? this.sendJsonTarget.classList.add("disabled-block") : this.sendJsonTarget.classList.remove("disabled-block");
  }

  initSendJsonMessage() {
    if (!this.hasSendJsonMessageTarget) return;

    this.unicloudSendJsonValue ? this.sendJsonMessageTarget.classList.remove("d-none") : this.sendJsonMessageTarget.classList.add("d-none");
  }

  initSendJsonDeleteButton() {
    if (!this.hasSendJsonDeleteButtonTarget) return;

    this.sendJsonDeleteButtonTarget.classList.toggle("d-none", !this.unicloudSendJsonValue || this.sessionConfigureNotEditableValue);
  }

  initParameters() {
    if (!this.hasParametersTarget) return;

    this.sessionConfigureNotEditableValue ? this.parametersTarget.classList.add("disabled-block") : this.parametersTarget.classList.remove("disabled-block");
  }

  loadConfigurationLogs() {
    if(this.stationIdValue){
      const url = `/stations/${this.stationIdValue}/channel_data?channel_datum[archive_type]=settings&equipment_id=${this.equipmentIdValue}&display=logs`;
      fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/javascript' } })
        .then(response => response.text())
        .then(html => {
          this.configurationLogsResultContainerTarget.innerHTML = html;
        });
    }
  }
}
