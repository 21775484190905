import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tariffsLoaded: { type: Boolean, default: false },
    tariffsOpened: { type: Boolean, default: false },
    stationId: Number,
    equipmentId: Number,
    eventTime: Number
  };

  loadTariffs(event) {
    let eventTarget = event.target;
    if(!this.tariffsLoadedValue){
      document.getElementById('archive-loading-spinner-' + this.eventTimeValue).classList.remove('d-none');
      const url = `/stations/${this.stationIdValue}/channel_data?channel_datum[archive_type]=daily&equipment_id=${this.equipmentIdValue}&event_time=${this.eventTimeValue}`;
      fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/javascript' } })
        .then(response => response.text())
        .then(html => {
          this.appendNewContent(html.trim(), eventTarget);
          this.tariffsLoadedValue = true;
          this.switchTariffsView(eventTarget);
          document.getElementById('archive-loading-spinner-' + this.eventTimeValue).classList.add('d-none');
        });
    }
    else{
      this.switchTariffsView(eventTarget)
    }
  }

  switchTariffsView(eventTarget){
    let classList = eventTarget.classList;
    let tariffElements = document.getElementsByClassName('tr-tariff-' + this.eventTimeValue);
    if(!this.tariffsOpenedValue){
      this.tariffsOpenedValue = true;
      classList.remove('chevron-down');
      classList.add('chevron-up');
      for (var i=0; i<tariffElements.length; i++) {
        tariffElements[i].classList.remove('d-none');
      }
    }
    else{
      this.tariffsOpenedValue = false;
      classList.remove('chevron-up');
      classList.add('chevron-down');
      for (var i=0; i<tariffElements.length; i++) {
        tariffElements[i].classList.add('d-none');
      }
    }
  }

  appendNewContent(html_string, eventTarget) {
    let currentTr = eventTarget.parentNode.parentNode;
    let currentTBody = currentTr.parentNode;
    let nextSibling = currentTr.nextSibling;
    let new_content = document.createElement('tbody');
    new_content.innerHTML = html_string;
    while (new_content.firstChild) {
      currentTBody.insertBefore(new_content.firstChild, nextSibling);
    }
  }
}
